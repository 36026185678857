@mixin themeOverrides($theme) {
    .text-primary {
        color: mat-color(map-get($theme, primary));
    }

    .bg-primary {
        background-color: mat-color(map-get($theme, primary)) !important;
        color: mat-color(map-get($theme, primary), default-contrast) !important;
    }

    .mat-button-toggle-checked {
        background-color: mat-color(map-get($theme, primary));
    }
    // not set on theming need to override it
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: mat-color(map-get($theme, primary));
    }

    .mat-raised-button.top-border {
        border-top: 1px solid map-get(map-get($theme, foreground), dividers);
    }

    .dl-modal {

        .dl-modal-header {
            background: map-get(map-get($theme, background), app-bar);
            border-bottom: 1px solid map-get(map-get($theme, foreground), dividers);
        }

        .dl-modal-footer {
            border-top: 1px solid map-get(map-get($theme, foreground), dividers);
        }

    }

    .dl-panel {
        border: 1px solid map-get(map-get($theme, foreground), dividers);

        .dl-panel-heading {
            background-color: map-get(map-get($theme, background), app-bar);
            border-bottom: 1px solid map-get(map-get($theme, foreground), dividers);
        }

        .dl-panel-footer {
            border-top: 1px solid map-get(map-get($theme, foreground), dividers);
        }

    }

    .synopsis {

        .condition {
            border-bottom: 1px solid map-get(map-get($theme, foreground), dividers);
        }

    }

    .dl-alert-danger {
        background-color: map-get(map-get($theme, warn), 100);
        border-color: map-get(map-get($theme, warn), 100);
        color: mat-color(map-get($theme, warn));
    }

    .dl-alert-border {
        border-color: map-get(map-get($theme, accent), 100);
    }

    .step-wizard-container {
        background: map-get(map-get($theme, background), background-alt);
        .step-wizard {
            .step-wizard-row {
                .step {
                    .dot {
                        color: white;
                        background-color: map-get(map-get($theme, background), disabled);
                    }

                    .active {
                        background-color: mat-color(map-get($theme, primary), default, 1);
                    }

                }
            }

            .step-wizard-row:before {
                background-color: map-get(map-get($theme, background), disabled);
            }
        }
    }

    //dsp colours
    dl-dsp {
        .nav-icon-left {
            color: map-get(map-get($theme, foreground), dividers);

        }
        .nav-icon-right {
            color: map-get(map-get($theme, foreground), dividers);
        }
        .dsp-navbar {
            nav {
                border-bottom: 1px solid map-get(map-get($theme, foreground), dividers);
            }
            .logo-holder {
                border: 1px solid map-get(map-get($theme, foreground), dividers);
                background-color: map-get(map-get($theme, background), card);
            }
        }

        p-accordion {
            .ui-accordion .ui-accordion-header {
                border: 1px solid map-get(map-get($theme, foreground), dividers);
            }

            .ui-accordion .ui-accordion-header a {
                background-color: map-get(map-get($theme, background), app-bar);
                color: map-get(map-get($theme, foreground), text);
            }

            .ui-accordion .ui-accordion-content {
                border-left: 1px solid map-get(map-get($theme, foreground), dividers);
                border-right: 1px solid map-get(map-get($theme, foreground), dividers);
                border-bottom: 1px solid map-get(map-get($theme, foreground), dividers);
            }
        }
    }

    .list-table-header {
        border-bottom: 2px solid map-get(map-get($theme, foreground), dividers);
    }

    .list-table-footer {
        border-top: 2px solid map-get(map-get($theme, foreground), dividers);
    }

    .list-table-row {
        border-top: 1px solid map-get(map-get($theme, foreground), dividers);
    }

    .dl-table {

        thead {
            border-bottom: 2px solid map-get(map-get($theme, foreground), dividers);
        }

        tfoot {
            tr {
                border-top: 2px solid map-get(map-get($theme, foreground), dividers);
                border-bottom: none;
                &.summary-total {
                    border-bottom: 2px solid map-get(map-get($theme, foreground), dividers);
                }
            }
        }

        tr {
            border-top: 1px solid map-get(map-get($theme, foreground), dividers);
        }

    }

    //org opt-in svg colour over-ride
    .org-icon-btn {
        &.mat-raised-button.mat-primary path {
            fill: mat-color(map-get($theme, primary), default-contrast);
        }
    }

    .info-popup {
        border: 1px solid map-get(map-get($theme, foreground), dividers) !important;
        background-color: map-get(map-get($theme, background), app-bar) !important;
        color: map-get(map-get($theme, foreground), text) !important;
        font-size:14px !important;
        font-weight: 400;
    }

    .dl-badge {
        background-color: map-get(map-get($theme, background), app-bar);
        color: map-get(map-get($theme, foreground), text);
        display: inline-flex;
        padding: 4px 9px;
        border-radius: 30px;
        font-size: 13px;
        line-height: 18px;
    }

    .dl-top-rule {
        border-top: 1px solid map-get(map-get($theme, foreground), dividers);
    }

    .discount-tag {
        color: map-get(map-get($theme, foreground), dividers);
    }

    .mat-hint.dl-error {
        color: mat-color(map-get($theme, warn));
    }

    .mat-nav-list .mat-list-item .mat-list-item-content {
        color: mat-color(map-get($theme, primary));
    }

    .dl-red {
        color: mat-color(map-get($theme, warn));
    }

    //escalation styles
    escalations-table {
        table {
            .mat-header-cell {
                border-color: #9c9b9b;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                background-color: #dddddd;
            }

            .mat-header-cell.highlighted {
                background: #9c9b9b;
            }

            .mat-header-cell.outer-heading {
                border-color: #9c9b9b;
                background-color: white;
                width: 10%;
                color: mat-color(map-get($theme, accent));
                border-style: none;
            }

            .mat-cell {
                text-align: center;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border-color: #9c9b9b;
            }

            .mat-cell.not-applicable {
                color: map-get(map-get($theme, background), disabled);
            }

            .mat-cell.selected-item {
                background: mat-color(map-get($theme, primary));
                font-weight: bold;
                color: white;
            }

            .mat-cell.current-item {
                background: white;
                font-weight: bold;
            }

            td, th {
                border-right-style: solid;
                border-right-width: 1px;
                &:first-child {
                    border-left-style: solid;
                    border-left-width: 1px;
                }
            }

            td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
                padding-right: 0;
            }

            td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
                padding-left: 0;
            }


        }

        .left-column-heading {
            float: left;
            height: 305px;
            width: 15px;
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            background-color: white;
            color: mat-color(map-get($theme, accent));


            .rotate {
                padding-top: 10px;
                padding-bottom: 4px;
                -ms-transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                position: relative;
                top: 180px;
                left: 0;
            }
        }
    } //end of escalation styles

    .dig-portal {
        .text-right .mat-sort-header-container {
            float: right;
        }

        td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
            padding-left: 0;
            padding-left: 0.5rem;
        }

        .mat-cell, .mat-footer-cell {
            font-size: small;
        }

        th {
            background-color: mat-color(map-get($theme, primary));
        }

        .mat-header-cell {
            color: white;
            &.text-right {
                text-align: right;
                justify-content: flex-end;
            }
            .mat-sort-header-arrow {
                color: white;
            }
        }

        .mat-raised-button.mat-accent {
            color: white;
        }

        .dashboard-stats {
            .number {
                font-weight: 500;
                font-size: 25px;
                color: white;
            }
            .title {
                font-size: smaller;
                white-space: pre-wrap;
            }
        }

        .stats-item {
            min-width: 115px;
        }

    }

}

@mixin darkColour($theme) {
    color: map-get(map-get($theme, foreground), text) !important;
}
