@import "themes-mixin";
$dl-default-margin: 10px;

// Define a custom typography config that overrides the font-family
@include mat-core(
        mat-typography-config(
            $font-family: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
            $body-1: mat-typography-level(16px, 24px, 300)
        )
);

body.#{$dl-app-name} {

    .dl-dropdown-panel {
        background: map-get(map-get($dl-app-theme, background), card) !important;
    }

    .dl-dropdown-panel-dark {
        background: map-get(map-get($dl-app-theme-dark, background), card) !important;

        .mat-option {
            color: map-get(map-get($dl-app-theme-dark, foreground), text)
        }

    }
}

.#{$dl-app-name} {

    .dark {
        @include themeOverrides($dl-app-theme-dark);
        @include darkColour($dl-app-theme-dark);
        @include angular-material-theme($dl-app-theme-dark);
    }

    @include themeOverrides($dl-app-theme);
    @include angular-material-theme($dl-app-theme);
}
