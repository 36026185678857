.quotes {
    background-color: $primary-color;
    color: #fff;

    .quotearea {
        min-height: 150px;
        padding: 40px 10px;

        .quote {
            margin-bottom: 10px;
            margin-top: 20px;
            font-weight: 300;
            text-align: center;
            img {
                position: absolute;
                top: 7px;
            }

            &:before,
            &:after {
                content: '"';
            }

        }

        .quoteauthor {
            font-weight: 300;
            text-align: center;
            &:before {
                content: ' - ';
            }
        }

    }

}
