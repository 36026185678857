//Base overrides
.mat-button-toggle-label {
  width:100%;
  text-align: center;
}

.mat-raised-button {
  margin: 2px 0 !important;
}

.mat-radio-button {
  .mat-radio-label-content {
    padding-left: 4px !important;
    font-weight: 300;
  }
}

//Modal styles
.dl-modal {

  .mat-dialog-content {
    padding: 0;
    margin: 0;
  }

  .mat-dialog-container {
    border-radius: 2px;
    padding: 0;
    min-width: 250px;
    min-height: 100px;
    max-height: 90vh;

    .mat-dialog-content {
      max-width: 80vw;
      max-height: 80vh;
    }

    @media (max-width: 768px) {
      .mat-dialog-content {
        max-width: 99vw !important;
        max-height: 70vh !important;
      }
    }

    .dl-modal-header {
      padding: $dl-default-margin;
      min-height: 18px;
      margin-bottom: 5px;
    }

    .dl-modal-body {
      padding: $dl-default-margin;
      &.dl-error {
        min-height: 60px;
      }
    }

    .dl-modal-footer, .dl-modal-footer-no-border {
      padding: $dl-default-margin;
      display: block;
    }

    .close {
      padding: 0;
      cursor: pointer;
      background: 0 0;
      font-size: 27px;
      line-height: 1;
      opacity: .2;
      color: #000;
    }

    .close:hover {
      opacity: .5;
    }

  }

  @media (min-width: 768px) {
    .qq-modal {
      width: 760px;
      .mat-dialog-content {
        max-width: 99vw !important;
      }
    }
    .dl-disclosure {
      .dl-modal-body {
        min-width: 600px;
      }
    }
    .dl-fc-dependant {
      .dl-modal-body {
        min-width: 650px;
      }
    }

      .dl-trip-dependant {
          .dl-modal-body {
              min-width: 650px;
          }
      }

      .dl-will-benefit {
          .dl-modal-body {
              min-width: 650px;
          }
      }

      .dl-single-benefit {
          .dl-modal-body {
              min-width: 650px;
          }
      }
  }

}

.mat-dialog-title {
  margin: 0 0 20px;
  display: block;
}

.mat-dialog-actions {
  padding: 1px / 2 0;
  display: flex;
  flex-wrap: wrap;

  &:last-child {
    // If the actions are the last element in a dialog, we need to pull them down
    // over the dialog padding, in order to avoid the action's padding stacking
    // with the dialog's.
    margin-bottom: -1px;
  }

  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }
}

.mat-slider-thumb {
  width: 30px !important;
  height: 30px !important;
  right: -15px !important;
  bottom: -15px !important;
}

.mat-slider-thumb-label {
  width: 35px !important;
  height: 35px !important;
}

.mat-slider-thumb-label-text {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.mat-slider-horizontal {
  width: 100%;

  .mat-slider-thumb-label {
    right: -17px !important;
    top: -43px !important;
  }
}

$toggle-button-columns-max: 6;

@for $i from 1 through $toggle-button-columns-max {
  .mat-button-toggle-#{$i} {
    width: 100% / $i;
  }
}

.mat-tab-body-wrapper {
  margin: 20px 0;
}

.mat-tab-label, .mat-tab-link {
  font-size: inherit;
  font-weight: 400;
}

.mat-checkbox {
  outline:0;
  .mat-checkbox-label {
    font-weight: 300;
  }
}

.mat-tooltip {
    white-space: pre-line;
    max-width: 440px !important;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

// panel base
.dl-panel {
  margin-bottom: 25px;

  .dl-panel-heading {
    padding: 10px $dl-default-margin;
  }

  .dl-panel-title {
    margin-top: 0;
    font-size: 21px;
  }

  .dl-panel-body {
    padding: $dl-default-margin;
  }

  .dl-panel-footer {
    padding: $dl-default-margin;
    text-align: right;
  }

}

// alert box default styles

.dl-alert {
  padding: $dl-default-margin;
  margin-bottom: 25px;
  border: 1px solid transparent;
  border-radius: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.fine-print {
  font-size: 12px;
  margin: 0;
}

.dl-action-row {
  padding-top: 15px;
}

medical .dl-action-row {
  padding: 0 20px 15px 20px;
}

medical .section-name {
  font-weight: bolder;
}

.dl-action-btn {
  line-height: 52px !important; //try to override material defaults
  font-size: 23px;
}

.dl-full-width {
  width: 100% !important; //this is to force material with styles override.
}

.dl-wrap-options {
    line-height: 24px !important;
    white-space: pre-wrap !important;

    label {
        line-height: 24px !important;
        white-space: pre-wrap !important;
    }
}

.dl-fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.dl-fade.in {
  opacity: 1;
}

.dl-radio {

  padding-right: 5px;
  padding-bottom: 1.25em;

  .mat-form-field-placeholder {
    display: block;
    margin-bottom: 0;
    position: inherit;
    transform: scale(0.75) perspective(100px) translateZ(0.001px);
    font-weight: 400;
    overflow: visible;
  }

  .mat-radio-label, .mat-form-field-placeholder {
    margin-bottom: 0;
  }

}

.dl-checkboxes {
  padding-bottom: 1.25em;
}

.dl-question {

  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-radio-label-content {
    padding-right: 8px;
  }

}

.dis-question {

  .mat-radio-label-content {
    padding-right: 8px;
  }

  .mat-radio-group {
    float: right;
  }

  .mat-checkbox {
    float: right;
    margin-right: 8px;
  }

}

.dl-subscript {
  font-size: 75%;
  color: #BABABA;
}

.recreation {
  h4 {
    margin-top: 0;
  }
}

.feedback {
  &.fa-check {
    color: green;
  }
  &.fa-times {
    color: red;
  }
}

.dl-loading {
  padding: 10px;
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 150px;
  z-index: 1000;
  text-align: center;
  background-color: rgba(150, 150, 150, 0.3);
  p {
    height: 100%;
    padding-bottom: 10px;
  }
  .fa-circle-o-notch {
    font-size: 60px;
    color: #eee;
  }
  &.absolute {
    position: absolute;
  }
  &.fixed {
    position: fixed;
    p {
      margin: 80px 10px 0;
    }
  }
}

.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

.dl-text-center {
  text-align: center;
}

@media (min-width: 992px) {
  .dl-right-bar {
    margin-top: 48px;
  }
  .dl-affix {
    position: relative;
  }
}

@media (max-width: 992px) {
  .dl-right-bar {
    padding: 0 20px;
  }
}

.mat-list.payment-summary {
  margin-bottom: 40px;
  .mat-list-item .mat-list-item-content {
    padding: 0;
  }
  .mat-list-item.summary-total {
    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 480px) {
    .mat-list-item .mat-list-item-content {
      align-items: normal;
    }
    .mat-list-item.summary-total {
      height: 70px;
    }
    .mat-list-item.initiation {
      height: 90px;
    }
  }
}

.dl-well {
  background: 0 0;
  border: none;
  box-shadow: none!important;
  -webkit-box-shadow: none!important;
  min-height: 20px;
  padding: 1rem;
  border-radius: 0;
  overflow: auto;
}

.dl-table {
    width: 100%;
    max-width: 100%;
    thead {
        tr {
            border-top: none !important;
        }
    }
    td, th {
        padding-top: 5px;
        padding-bottom: 5px;
        vertical-align: bottom;
    }
    tfoot {
        .initiation td {
            vertical-align: top;
        }
    }

}

.dash-link.active {
    background-color: #ededed;
}
