//Note the colour need to be done in the theme-ing / base scss file as not to import the material themes more than once.

.step-wizard-container {
  padding-top: 20px;
  padding-bottom: 10px;

  .step-wizard {
    display: table;
    width: 100%;
    position: relative;

    .step-wizard-row {
      display: table-row;
      .step {
        display: table-cell;
        text-align: center;
        position: relative;
        width: 163px;

        .dot {
          //remember to do colours in base
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;
          border: 1px solid transparent;
          white-space: nowrap;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          padding: 3px 0 0 1px;
          width: 26px;
          height: 26px;
          text-align: center;
          font-size: 14px;
          line-height: 1.428571429;
          border-radius: 13px;
          cursor: default;
          opacity: 1 !important;
          font-weight: 400;
        }

        p {
          margin: 10px 0 0;
        }
      }
    }

    .step-wizard-row:before {
      top: 13px;
      bottom: 0;
      position: absolute;
      width: 86%;
      margin-left: 7%;
      height: 1px;
      //we need to set a colour
      content: " ";
    }
  }
}

@media (min-width: 768px) {
  .step-wizard-container {
    margin: 0px;
    .step-wizard {
      margin-top: 20px;
      margin-bottom: 20px;
      .step-wizard-row {
        .step {
          .dot {
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 23px;
            line-height: 1.428571429;
            border-radius: 20px;
            cursor: default;
          }
        }
      }
      .step-wizard-row:before {
        top: 20px;
      }
    }
  }
}

.embedded .step-wizard-container {
  .step-wizard {
    margin-top: 4px;
    margin-bottom: 0;
    .step-wizard-row {
      .step p {
        margin-top: 2px;
      }
    }
  }
}
