.dig-portal {

    .card-panel {
        margin: 1rem;
        box-shadow: 0px 8px 25px #19668E26;
    }

    .dashboard-content {
        background: #EFF2F8 0 0 no-repeat padding-box;
    }

    .dig-login-card {
        padding: 15px;
        margin: 6% auto 0 auto;
        text-align: center;
        max-width: 600px
    }

}

.dig-landing-detail {

    .collection-item .fa {
        color: $primary-color;
    }

    .quick-quote-border {
        background-color: #fff;
        margin-bottom: 30px;
        border: 1px solid $collection-border-color;
    }

}
