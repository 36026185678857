h2, h3, h4, h5, h6 {
    font-weight: 300;
}

#white_logo {
    display: none;
    width: 180px;
    margin-top: 15px;
    margin-left: 18px;
}

#normal_logo {
    width: 180px;
    margin-top: 15px;
    margin-left: 18px;
}

#mobile-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
    .menu {
        background-color: #fff;
        li {
            font-size: 18px;
            text-align: center;
        }
    }
}

//override original button
.btn {
    text-decoration: none;
    color: #fff;
    background-color: $primary-color;
    text-align: center;
    letter-spacing: .5px;
    @extend .z-depth-1;
    transition: background-color .2s ease-out;
    cursor: pointer;
    &:hover {
        background-color: #d1d3d4;
        @extend .z-depth-1-half;
    }
}

a.safa-link {
    color: #406eaa;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.theme-colour {
    color: $primary-color;
}

.dl-dsp h2 {
    color: $primary-color;
}

.dl-action-btn.dl-alternate {
    background-color: $dl-light-grey !important;
}

.watermark {
    display: none;
}

.light-grey-bg .watermark {
    position: fixed;
    z-index: -20;
    &.top {
        display: none;
        @media only screen and (min-width : 700px) and (min-height: 760px) {
            display: block;
            width: 300px;
            top: 15px;
            left: -60px;
            filter: blur(1px) brightness(90%);
            @media #{$extra-large-and-up} and (min-height: 860px) {
                width: 350px;
                top: -20px;
                left: -50px;
            }
        }
    }
    &.bottom {
        display: block;
        bottom: -140px;
        left: -50px;
        width: 550px;
        filter: blur(1px);
        @media #{$medium-and-up} {
            filter: blur(2px) brightness(92%);
        }
        @media #{$extra-large-and-up} and (min-height: 780px) {
            width: 650px;
        }
    }
}
body.light-grey-bg {
    background-image: url('/themes/life-web-oc-thm-dlza/assets/images/watermark_d.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 1580px;
    background-attachment: fixed;
    background-color: white;
    @media #{$medium-and-up} {
        .dsp-mobile-container {
            background: #ffffffb3;
        }
    }
    @media #{$medium-and-down} {
        background-image: url('/themes/life-web-oc-thm-dlza/assets/images/watermark_m.png');
        background-position: center center;
        background-size: 150px;
    }
}

.ffs-bg {
    background-image: url('/themes/life-web-oc-thm-dlza/assets/images/watermark.svg');
    background-repeat: no-repeat;
    background-size: 450px;
    background-position-x: -60px;
    background-position-y: -20px;
}

.dl-cookie-banner {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: black;
    color: white;
    padding: 15px;
    opacity: 0.85;
    z-index: 2147483640;
}

.dl-cookie-banner-dismiss {
    color: white;
    cursor: pointer;
}

.section-101 {
    height: 400px;
}

@media #{$medium-and-up} {
    .header-qq-space {
        min-height: 520px;
    }
}

@media #{$large-and-up} {
    div.dl-row.row.demo-sp-margin {
        padding: 0 0 0 4rem;
    }
}

@media #{$extra-large-and-up} {
    div.dl-row.row.demo-sp-margin {
        padding: 0 0 0 6rem;
    }
}

.org-donut {

    .center-txt {
        fill: #fff;
        font-weight: 400;
        font-size: 30px;
    }

}

.dl-disclosure {
    .dis-question-head {
        font-weight: 400;
    }
}

.map-responsive {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}

.map-responsive iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.qq-dl-logo {
    padding-top: 10px;
    @media #{$small-and-down} {
        height: 80px;
        border-bottom: 1.5px solid;
        padding-bottom: 20px;
        padding-left: 50px;
        padding-right: 50px;
        border-color: white;
    }
}

.qq-omart-logo {
    padding-top: 10px;
    height: 75px;
    @media #{$small-and-down} {
        padding-top: 10px;
        height: 70px;
    }
}

    .do-different {
        @media only screen and (max-width: 599px) {
            display: none;
        }
        @media only screen and (min-width: 1501px) {
            display: none;
        }
        @media only screen and (max-width: 1500px) and (min-width: 600px) {
            position: absolute;
            left: 6px;
            margin-top: -6px;
        }
    }
    .do-different-main {
    @media only screen and (max-width: 1500px) and (min-width: 600px) {
       display:none;
        }
    }

    .do-different-button {
            @media only screen and (max-width: 1500px) and (min-width: 600px) {
                position: absolute;
                margin-top: 300px;
                left: 70px;
            }
        }
.step-wizard-container {
    @media #{$small-and-down}
    {
        font-size: 12px;
    }
}

.mat-raised-button.mat-primary.maybe-later {
        background-color: #00A69C;
    }
.mat-raised-button.mat-primary.not-interested {
        background-color: #707070;
    }




