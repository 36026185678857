@import "../app/sp/step/steps";

.sales-process-main {
    position: relative;
}

.synopsis {

  h5 {
    margin: 0;
  }

  .dl-panel-body {
    padding: 0 10px;
  }

  .condition, .condition-last {
    padding: 10px 0;
  }

}

.add-condition-button.mat-mini-fab {

  height: 22px;
  width: 22px;

  .mat-button-wrapper{
    display: block;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }

}

.dl-medical-section {
  padding-left: 5px;
}

.underwriting {

  .dl-question {
    margin-bottom: 0;
  }

}

#paymentInput .mat-tab-body-content {
  margin: 0;
}
