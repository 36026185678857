
.dl-dsp {

    position: relative;
    min-height: 150px;

    .center-align {
        text-align: center;
    }

    .nav-icon {
        padding-left: 0;
        list-style: none;
        margin: 5px;
    }

    .nav-icon-left {
        @extend .nav-icon;
        float: left !important;

    }

    .nav-icon-right {
        @extend .nav-icon;
        float: right !important;
    }

    .dsp-container-fluid {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .dsp-navbar {
        nav {
            min-height: 40px;
            top: 0;
            position: relative;
            right: 0;
            left: 0;
            z-index: 100;
            margin-bottom: 0;
        }
        .logo-holder {
            float: none;
            position: absolute;
            left: 50%;
        }
    }

    .dsp-edit-group-icon {
        padding-left: 15px;
        display: inline-flex;

        .mat-mini-fab {
            width: 28px !important;
            height: 28px !important;

            i {
                padding: 0px 0px 12px 0px !important;
            }
        }
    }

    .dsp-row-padding {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .dsp-list-btn {
        height: 20px;
        line-height: 0 !important;
    }

    //accordian stuff
    p-accordion {

        .ui-accordion {
            width: 100%;
            margin-bottom: 25px;
        }

        .ui-accordion .ui-accordion-header {
            cursor: pointer;
            position: relative;
            margin-top: 4px;
        }

        .ui-accordion .ui-accordion-header a {
            display: block;
            padding: 10px 15px;
            text-decoration: none;
            h3 {
                margin: 0;
                font-weight: 300;
                width: 100%;
            }
        }

        .ui-accordion .ui-accordion-header .fa .fa-fw {
            display: none !important;
        }

        .ui-accordion .ui-accordion-content {
            padding: 15px;
            border-top: 0;
            overflow: hidden;
            p {
                font-weight: 300;
            }
        }

        .ui-accordion .ui-accordion-header.ui-state-disabled,
        .ui-accordion .ui-accordion-header.ui-state-disabled a {
            cursor: default;
        }

        .ui-accordion-content-wrapper-overflown {
            overflow: hidden;
        }

    }

    .dsp-scroll-area {
        max-height: 380px;
        overflow-y: scroll;
    }

    dsp-page .col {
        margin-bottom: 10px;
    }

    .dynamic-component {
        p {
            margin: 0 0 10px 0;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }

    .page.no-title {
        margin-top: 10px;
    }

}
