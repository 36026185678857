header {

    img.omart-logo {
        width: 240px;
        height: 35px;
        margin-top: 20px;
        margin-right: 15px;
    }

}

.trans-head {
    &.top {
        header {
            #white_logo {
                display: initial;
            }
            #normal_logo {
                display: none;
            }
        }
    }
}

