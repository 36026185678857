footer {

    h4 {
        color: $primary-color;
    }

    .row.menus {
        background: $secondary-color;
    }

    .row.menus h4 {
        color: #e0861a;
        border-bottom: 1px solid $primary-color;
        padding: 0 0 15px 0;
    }

    .row {
        &.info, &.menus, &.copy {
            border-top: 1px #ccc dotted;
        }
    }

    @media #{$medium-and-up} {

        .info .info-content {
            padding: 20px 50px;
        }

        .row {
            &.info .col.border-right {
                min-height: 320px;
                border-right: 1px #ccc dotted;
            }

            &.menus .col.border-left {
                min-height: 210px;
                border-left: 1px #ccc dotted;
            }
        }
    }

    @media #{$small-and-down} {
        .row {
            .border-left {
                border-bottom: 1px #ccc dotted;
            }
            &.menus .col.border-left {
                min-height: 240px;
                border-left: 1px #ccc dotted;
            }
        }
    }

    .copywrite {
        font-size: 14px;
        color: #ffffff;
        line-height: 1.4em;
        font-weight: 500;
        margin-left: 2px;
    }

    .omart-logo {
        width: 230px;
        height: 42px;
        float: right;
        @media #{$large-and-up} {
            margin-top: 25px;
        }
        @media only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen}){
            margin-top: 45px;
        }
        @media only screen and (min-width : 600px) and (max-width : 679px){
            margin-top: 65px;
        }
        @media #{$small-and-down} {
            float: left;
            width: 70%;
            height: 45px;
            margin-top: 15px;
            margin-bottom: 30px;
            margin-left: 2px;
        }

    }

    .theme-background {
        background-color: $primary-color;
    }

    .bottom-space {
        border-top: 1px #ccc dotted;
        padding-bottom: 45px;
    }
}

.sp-footer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 35px;
    color: white;
    overflow: hidden;
    min-height: 10vh;
    margin-left: 6px;
    margin-bottom: 25px;

    img.omart-logo {
        height: 35px;
        margin-right: 15px;
        float: left;
    }

    .bottom-space {
        border-top: 1px #ccc dotted;
        padding-bottom: 45px;
    }

}

