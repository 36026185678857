@import '../../../node_modules/@angular/material/_theming';

$mat-dl-orange: (
   50: #E7922D,
  100: #E7922D,
  200: #E7922D,
  300: #E7922D,
  400: #E0861A,
  500: darken(#E0861A, 10%),
  600: darken(#E0861A, 20%),
  700: darken(#E0861A, 30%),
  800: darken(#E0861A, 40%),
  contrast: (
     50: $white-87-opacity,
    100: $white-87-opacity,
    200: $white-87-opacity,
    300: $white-87-opacity,
    400: $white-87-opacity,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
  )
);

$mat-dl-foreground: (
    base:              black,
    divider:           $black-12-opacity,
    dividers:          #DDDDDD,
    disabled:          rgba(black, 0.38),
    disabled-button:   rgba(black, 0.38),
    disabled-text:     rgba(black, 0.38),
    hint-text:         lighten(#000000, 33.5%),
    secondary-text:    rgba(black, 0.54),
    icon:              rgba(black, 0.54),
    icons:             rgba(black, 0.54),
    text:              lighten(#000000, 33.5%),
    slider-min:        #E7922D,
    slider-off:        #E7922D,
    slider-off-active: #E7922D,
);

$mat-dl-foreground-dark: (
    base:              white,
    divider:           $white-12-opacity,
    dividers:          #DDDDDD,
    disabled:          rgba(white, 0.3),
    disabled-button:   rgba(white, 0.3),
    disabled-text:     rgba(white, 0.3),
    hint-text:         rgba(white, 0.3),
    secondary-text:    rgba(white, 0.7),
    icon:              white,
    icons:             white,
    text:              white,
    slider-min:        white,
    slider-off:        rgba(white, 0.3),
    slider-off-active: rgba(white, 0.3),
);

$mat-dl-background: (
    status-bar: map_get($mat-grey, 300),
    app-bar:    #F5F5F5,
    background: #ffffff,
    background-alt: #ddd,
    hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
    card:       white,
    dialog:     white,
    disabled: #9C9B9B,
    disabled-button: $black-12-opacity,
    raised-button: white,
    focused-button: $black-6-opacity,
    selected-button: map_get($mat-grey, 300),
    selected-disabled-button: map_get($mat-grey, 400),
    disabled-button-toggle: map_get($mat-grey, 200),
    unselected-chip: map_get($mat-grey, 300),
    disabled-list-option: map_get($mat-grey, 200),
);

$mat-dl-background-dark: (
      status-bar: black,
      app-bar:    map_get($mat-grey, 900),
      background: #303030,
      background-alt: #ddd,
      hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
      card:       map_get($mat-grey, 800),
      dialog:     map_get($mat-grey, 800),
      disabled: #9C9B9B,
      disabled-button: $white-12-opacity,
      raised-button: map-get($mat-grey, 800),
      focused-button: $white-6-opacity,
      selected-button: map_get($mat-grey, 900),
      selected-disabled-button: map_get($mat-grey, 800),
      disabled-button-toggle: black,
      unselected-chip: map_get($mat-grey, 700),
      disabled-list-option: black,
);

$mat-dl-red: (
  50: lighten(#D9534F, 40%),
  100: lighten(#D9534F, 30%),
  200: lighten(#D9534F, 20%),
  300: lighten(#D9534F, 10%),
  400: #D9534F,
  500: #D9534F,
  600: darken(#D9534F, 20%),
  700: darken(#D9534F, 30%),
  800: darken(#D9534F, 40%),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $white-87-opacity,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
  )
);

@function mat-dl-theme(
    $primary,
    $accent,
    $foreground: $mat-light-theme-foreground,
    $background: $mat-light-theme-background,
    $isDark: false) {
  @return (
        primary: $primary,
        accent: $accent,
        warn: mat-palette($mat-dl-red),
        is-dark: $isDark,
        foreground: $foreground,
        background: $background,
  );
}

$dl-app-primary: mat-palette($mat-dl-orange, 400, 200);
$dl-app-accent: mat-palette($mat-dl-orange, 200, 100, 300);
$dl-app-theme: mat-dl-theme($dl-app-primary, $dl-app-accent, $mat-dl-foreground, $mat-dl-background);
$dl-app-theme-dark: mat-dl-theme($dl-app-primary, $dl-app-accent, $mat-dl-foreground-dark, $mat-dl-background-dark, true);
$dl-app-name: "DL";
